import React from "react"

import Header from "../components/header"
import Footer from "../components/footer"

import SEO from "../components/seo"

const NotFoundPage = () => (
  <>
    <Header />
    <SEO title="404: Seite existiert nicht!" description="Diese Seite existiert nicht" lang="de"/>
    <h1 className="indexHeadline">Nicht gefunden!</h1>
    <section>
      <div className="philosophieDiv">
        <p className="indexText">Diese Seite existiert nicht!</p>
      </div>
    </section>
    <Footer />
  </>
)

export default NotFoundPage